<template>
  <popup-pane @close="close" :title="$t('profile.buddies.title--add-buddy')" :dark="isCheckedIn">

    <div v-if="!!showBuddies">
      <div v-if="buddies && buddies.length" class="flex flex-col space-y-2">
        <safety-buddy-item v-for="buddy in buddies" :key="buddy.identifier" :buddy="buddy" />
      </div>
      <div v-else>
        <p>{{ $t("profile.buddies.empty") }}</p>
      </div>
    </div>

    <form @keydown="form.onKeydown($event)" @submit.prevent="addNewBuddy" class="mt-8">
      <t-input-group class="mb-4">
        <t-input placeholder="Jason Cooke" v-model="form.name" />
        <p v-if="form.errors.has('name')">{{ form.errors.get("name") }}</p>
      </t-input-group>

      <t-input-group class="mb-4">
        <t-select v-model="countryDialCode" :options="countries" value-attribute="dialCode" text-attribute="name" />
      </t-input-group>

      <div class="flex mb-6">
        <t-input-group class="w-20">
          <t-input disabled class="border-r-0 rounded-tr-none rounded-br-none" :value="countryDialCode">
          </t-input>
        </t-input-group>
        <t-input-group class="flex-1">
          <t-input placeholder="466778899" v-model="form.phone" class="rounded-tl-none rounded-bl-none" />
          <p v-if="form.errors.has('phone')">{{ form.errors.get("phone") }}</p>
        </t-input-group>
      </div>
      <t-button type="submit" :disabled="formLoading || !formValid">{{
      formLoading ? $t("action.saving") : $t("action.save")
      }}</t-button>
    </form>
  </popup-pane>
</template>

<script>
import Form from "vform";

import { mapGetters } from "vuex";
import { UPDATE_BUDDIES } from "@/store/actions/user";

import PopupPane from "@/components/PopupPane";
import SafetyBuddyItem from "@/components/Settings/SafetyBuddyItem";

import Repository from "@/repositories/RepositoryFactory";

const userRepository = Repository.get("user");

export default {
  name: "AddSafetyBuddy",

  components: {
    SafetyBuddyItem,
    PopupPane
  },

  props: {
    open: {
      type: Boolean,
    },
    showBuddies: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.countryDialCode = this.countries[0].dialCode;
  },

  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
      }),
      formLoading: false,
      countryDialCode: null,
      countries: require("@/utils/country_codes.json"),
    };
  },

  methods: {
    async addNewBuddy() {
      this.formLoading = true;

      try {
        await userRepository.addBuddy(
          this.form.name,
          `${this.countryDialCode}${this.form.phone}`
        );

        this.$store.dispatch(UPDATE_BUDDIES);
      } catch (e) {
        console.log(e.message);
      }

      this.close();
      this.formLoading = false;
    },

    close() {
      this.resetForm();
      this.$emit("close");
    },

    resetForm() {
      this.form.clear();
      this.form.reset();
    },
  },

  computed: {
    formValid() {
      return !!this.form.name && !!this.form.phone;
    },

    ...mapGetters({ buddies: "getBuddies", isCheckedIn: "isCheckedIn" }),
  },
};
</script>

<style scoped></style>
