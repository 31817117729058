<template>
  <list-item @click.native="safetyBuddyOpen = true" class="relative">

    <template v-slot:start>
      <!-- <svg
        class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500"
        viewBox="0 0 20 20" fill="currentColor">
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
          <path fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd" />
      </svg> -->
      <icon-buddy class="w-6 text-brand-dark" />
      <div class="flex flex-col justify-center flex-1 ml-4 text-brand-dark">
        <p class="text-sm">{{ buddy.name }}</p>
        <p class="text-lg font-bold">{{ buddy.phone }}</p>
      </div>
    </template>

    <template v-slot:end>
      <span @click.prevent="deleteBuddy"
        class="flex items-center justify-center w-8 h-8 rounded-full bg-brand-primary text-brand-dark shadow-primary-blue">
        <icon-loading class="w-4 animate-spin" v-if="deleting" />
        <icon-close class="w-4" v-else />
      </span>
      <!-- <icon-buddy class="w-6 text-brand-dark" /> -->
    </template>

  </list-item>
</template>

<script>

import ListItem from "@/components/ListItem.vue";
import Repository from "@/repositories/RepositoryFactory";

// import IconForward from "assets/icons/forward.svg";
import IconBuddy from "assets/icons/buddy.svg";
import IconClose from "assets/icons/close.svg";

import { UPDATE_BUDDIES } from "@/store/actions/user";

const userRepository = Repository.get("user");

export default {
  name: "SafetyBuddyItem",

  components: {
    IconBuddy,
    // IconForward,
    IconClose,
    ListItem
  },

  data() {
    return {
      deleting: false,
      safetyBuddyOpen: false,
    };
  },

  methods: {
    
    async deleteBuddy() {
      this.deleting = true;

      try {
        const confirmDelete = confirm(
          this.$t("profile.buddies.confirm_delete")
        );

        if (!confirmDelete) return;

        await userRepository.forgetBuddy(this.buddy.identifier);
        await this.$store.dispatch(UPDATE_BUDDIES);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.deleting = false;
      }
    },
  },

  props: {
    buddy: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
